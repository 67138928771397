import React from 'react'
import Header from "../../components/Header"
import BlogCard from './BlogCard'
import HeaderImage from '../../images/blogH.JPG'
import Meta from '../../components/Meta'


import './blog.css'
import { Link } from 'react-router-dom'

const Blog = () => {

  return (
    <>
    <Meta title={"Blog"} />
    <Header title="WHAT’S TRENDING..., NEWS, UPDATES, JOBS!" image={HeaderImage}
      children= {
        <div className="bttn__card">
          <button className="lnk">
          <Link to="/contact" className='lnk'> Contact us </Link>
          </button>
        </div>}>
    </Header>

       <section className="blog padding">
        <div className="container grid2">
        <BlogCard/>
        </div>
       </section>
    </>
  )
}

export default Blog