import React from 'react'

const MyTable = () => {
  return (
    <div>
         <table>
        <tbody>
          <tr>
            <td>Organization's name: ''</td>
            <td>Odins Medical LTD</td>
          </tr>
          <tr>
            <td>Address:</td>
            <td>Collingwood Buildings, 38 Collingwood Street, Newcastle Upon Tyne, NE1 1JF. </td>
          </tr>
          <tr>
            <td>Telephone:</td>
            <td>0191 323 0057 </td>
          </tr>
          <tr>
            <td>Email Address:</td>
            <td>Admin@odinsmedical.com  </td>
          </tr>

          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  )
}

export default MyTable