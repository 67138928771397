import Card from '../../UI/Card'
import Cards from '../../UI/Cards'
import Cimage from '../../images/candidh.jpg'
import Climage from '../../images/clienth.jpg'

import './programs.css'

const Programs = () => {

  return (
    <>

    <>

    <section className='section__vwrapper'>

      <div className='container vprograms'>
          <div className="programs__vwrapper">
              <Cards
              title='Quality staffing solution just a click away... '
              body=' Odins Medical don’t just provide your staffing solutions 247, we take pride in the level of quality care our candidate brings to your healthcare facility.'
              />
          </div>

          <div className="img__vwrapper">
            <img src={Climage} alt="client bg" />
          </div>

      </div>

    </section>

    </>

    <>
    <section className='section__wrapper'>

      {/* content */}
        <div className='container programs'>
            <div className="programs__wrapper">
                <Card
                title='Join us '
                body= "Register with us now to benefit from our competitive pay rates, confidence-boosting training and support, and seamless operations driven by tech."
                />
            </div>

          {/* image */}
          <div className="img__wrapper">
            <img src={Cimage} alt="candidate bg" />
          </div>

        </div>

    </section>

    </>

    </>
    
  )
}

export default Programs