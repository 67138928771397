import {FaWhatsapp} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {RiTwitterXFill} from 'react-icons/ri'

import './Header.css'


const Header = ({title, image, children}) => {
  return (
    <header className="header">
        <div className="header__container">
            <div className="header__container-bg">
                <img src={image} alt="Header Background" />
            </div>
            <div className="header-body">
              <div className="header__content">
                  <h1>{title}</h1>
                  <div className='header-button'>{children}</div>
              </div>
              <div className="header-socials">
                <h3>Follow us here:</h3>
                <div className="h-svg">
                  <a href="https://wa.me/01913230057" target='_blank' rel='noreferrer noopener'> <FaWhatsapp/> </a>
                  <a href="https://www.facebook.com/people/Odins-Medical/61550256904872/" target='_blank' rel='noreferrer noopener'> <FaFacebookF/> </a>
                  <a href="https://twitter.com/OdinsMedical" target='_blank' rel='noreferrer noopener'> <RiTwitterXFill/> </a>
                </div>
              </div>
            </div>
        </div>
    </header>
  )
}

export default Header