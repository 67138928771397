import {Link} from 'react-router-dom'
import Logo from '../images/logo.jpg'
import {FaWhatsapp} from 'react-icons/fa'
import {FaFacebookF} from 'react-icons/fa'
import {RiTwitterXFill} from 'react-icons/ri'

const Footer = () => {
  return (
    <>

    <footer>
        <div className="container footer__container">

            <section>
                <Link to="/" className='logo'>
                    <img src={Logo} alt="footer Logo" />
                </Link>
                <p>
                  The agency that takes pride in matching each vacant position with the best possible staff!  <span className='TtT'> #MatchingCare </span>
                </p>
                <div className="footer__socials">
                    <a href="https://wa.me/01913230057" target='_blank' rel='noreferrer noopener'> <FaWhatsapp/> </a>
                    <a href="https://www.facebook.com/people/Odins-Medical/61550256904872/" target='_blank' rel='noreferrer noopener'> <FaFacebookF/> </a>
                    <a href="https://twitter.com/OdinsMedical" target='_blank' rel='noreferrer noopener'> <RiTwitterXFill /> </a>
                </div>
            </section>

            <section className='ulink'>
                <h4>Useful Links</h4>
                <Link to="/services" className='lnk'>Care & support services</Link>
                <Link to="/client" className='lnk'>Staffing solutions</Link>
                <Link to="/Candidate" className='lnk'>Candidate</Link>
                <Link to="/blog" className='lnk'>Blog</Link>
                <Link to="/contact" className='lnk'>Contact</Link>
                <Link to="/policy" className='lnk'> Policy </Link>
                <Link to="/o-hub" className='lnk'> O-Hub </Link>
            </section>

        </div>
        <div className="footer__copyright">
            <small>2023 Odins Medical &copy; All Rights Reserved</small>
        </div>
    </footer>

    </>
  )
}

export default Footer