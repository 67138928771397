import {Link} from 'react-router-dom'
import Image from '../../images/AboutH.jpg'

import './AboutComp.css'

const About = () => {
  return ( 
    <section className='about__header'>

      <div className="container main__about-container">
          <div className="about__left-content">
            <h3>Who we are </h3>
            <p>
              Odins Medical is a fast-growing provider of care and support services, and also temporary and permanent Nursing staff solution to both private clients, and public and private healthcare organizations. Our esteemed team are made up of healthcare professionals that have firsthand experience of temporary and permanent Nursing placements across UK healthcare organizations, and extensive experience in home care and support industry. Hence, we take pride in delivering services which are tailored to candidate and client needs. 
            </p>
            <div className="bttn__card">
              <button>
              <Link to="/about" className='lnk'>More info</Link>
              </button>
            </div>
          </div>

          <div className="main__about-image">
            <img src={Image} alt="Main About"/>
          </div>
      </div>

   </section>
  )
}

export default About