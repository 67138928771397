import MainHeader from '../../components/MainHeader'
import CareSupport from '../../components/CareSupport/CareSupport'
import Programs from '../../components/Programs/Programs'
import About from '../../components/aboutComp/About'
import Contact from '../../components/ContactComp/Contact'
/*import TrustedBy from '../../components/TrustedBy'*/
/*import Hblog from './Hblog'*/
/*import Testimonials from '../../components/Testimonials'*/
/*import FAQs from '../../components/FAQs'*/

import './home.css'





const Home = () => {
  return (
    <>

    <MainHeader/>
    <CareSupport/>
    <Programs/>
    <About/>
    <Contact/>
    {/*<TrustedBy/>*/}
    {/*<Hblog/>*/}
    {/*<Testimonials/>*/}
    {/*<FAQs/>*/}

    </>

  )
}

export default Home