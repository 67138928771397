import React, { useState } from 'react';
import { serviceDropdown } from '../data';
import { Link } from 'react-router-dom';
import "./Dropdown.css";



const Dropdown = ({ setIsNavShowing }) => {
    const [dropdown, setDropdown] = useState(false);

  return (
    <>
    <ul
     className= {dropdown ? "services-submenu clicked" : "services-submenu"}
    //  onClick = {()=> setDropdown(!dropdown)}
    >
        {serviceDropdown.map(item => {
            return (
                <li key={item.id}>
                    <Link 
                     to={item.path} 
                     className={item.CName}
                     onClick={() => {
                        setDropdown(false); // Close the dropdown menu
                        setIsNavShowing(false); // Close the navbar
                      }}
                    >
                        {item.title}
                    </Link>
                </li>
            )
        })}
    </ul>
    </>
  )
}

export default Dropdown