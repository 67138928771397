import React from 'react'
import {Link} from 'react-router-dom'
import serviceImg from '../../images/serviceImg.jpg'
import './CareSupport.css'

const CareSupport = () => {
  return (
    <section className="care-support">
        <div className="container cs-container">
            <div className="main-cs-image">
                <img src={serviceImg} alt="Care Support"/>
            </div>
        

            <div className="cs-content">
                <h3>Care and support services</h3>
                <p>
                    ‘There is no place like home’, there is no place to be while receiving care and support like your home. Odins Medical care professionals are at your fingertips to provide you or your loved ones the best-practice care and support at your home where your feeling for comfort, security and dignity is most profound.
                </p>

                <div className="bttn__card">
                    <button>
                    <Link to="/services" className='lnk'>More info</Link>
                    </button>
                </div>
            </div>

        </div>
    </section>
  )
}

export default CareSupport