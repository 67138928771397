import {Link} from 'react-router-dom'
import './contactComp.css';

const Contact = () => {
  return (
   <section className='cont__container'>

   <section className='cont__container'>
    <div className='cont'>
      <div className='container flexSB'>
        <div className='right row'>
        <div className='box last'>
            {/* <h3>ODINS MEDICAL</h3> */}
            <ul>
              <li>
                <i className='fa fa-map'></i>
                <div className="addList">
                <p>Collingwood Buildings</p>
                <p>38 Collingwood Street</p>
                <p>Newcastle Upon Tyne</p>
                <p>NE1 1JF</p>
                </div>
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                0191 323 0057 
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                Admin@odinsmedical.com 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
   </section>

   <div className="btn__card">
          <button>
          <Link to="/contact" className='lnk'>Contact us</Link>
          </button>
    </div>

   </section>
  )
}

export default Contact