import Header from "../../components/Header"
import HeaderImage from '../../images/blogH.JPG'
import BlogImage from '../../images/Blog2.jpg'

import "./blogPages.css"
import {Link} from 'react-router-dom'



const Blog2 = () => {
  return (
    <>
    <Header title="WHAT’S TRENDING..., NEWS, UPDATES, JOBS!" image={HeaderImage}>
    </Header>

    <section className="blog__vision">
        <div className="blog__section-image">
            <img src={BlogImage} alt="" />
        </div>
      <div className="container blog-container">
        <div className="blog__section-content">
        <h2>Nursing Pay Rise 2023: What it means for Agency Nurses</h2>
            <p>
             Remuneration for nurses has been a complex contentious issue in the United Kingdom (UK) since the establishment of the National Health Service (NHS). The NHS itself is a publicly funded entity, this is to say that the health fund is limited in its ability to fund health workers under its umbrella(Royal College of Nursing, 2024). Specifically, the NHS funds the large majority of nurses as NHS nurses; conversely, nurses can be employed either through agencies or trust banks. The divided categories of nurses naturally create discrepanciesregarding the remuneration of nurses occupying these groups depending on their terms of service.
            </p>
            <p>For instance, NHS-funded nurses rely solely on public sector funding and therefore additional benefits may require changes to governmental policies to facilitate fair compensation(Royal College of Nursing, 2024). Additionally, the cost of living in the UK has risen due to yearly increases in inflation and therefore the standard of living for a majority of the NHS nurses drops on regular pay(Royal College of Nursing, 2024). As a result, these cadre of nurses require simultaneous increases in pay to cater for housing, meals and transportation. These inconsistencies force a good chunk of the working NHS nurses to leave the nursing practice all together due to workload, burnouts and last but not least, a less rewarding salary.</p>
            <p>Therefore, this begs the question, why?Current statistics reveal that the there is an increased demand for nurses in the UK against a very limited supply(ProMedical Personnel Ltd, 2023). Consequently, healthcare facilities or programs seek the services of agency nurses to fill the void. However, this comes at a cost, agency nurses set terms where they command their rates on an hourly basis for the services they provide(ProMedical Personnel Ltd, 2023).Overall, they tend to have a higher annual pay compared to NHS nurses whose pay is adjusted by their pay band, which ranges from Band 5 to Band 9, with Band 5 being the entry level.</p>
            <p>The UK government has stepped its efforts through a decisive policy intervention and instituted a pay raise for the NHS nurses. The pay rise took effect on May 2023, that saw an increase of 5%, alongside a one-off payment of £1900 and £3000, depending on levels of seniority(Department of Health and Social Care, 2024).Generally, this increases the average yearly salary of the nurse to between £33,000 and £37,000(Department of Health and Social Care, 2024). In the scheme, band 5 nurses who are entry level nurse will receive up to£34,000 with additional years’ experience and qualifications; in contrast; band 7 and beyond will earn at a minimum of £43,000 a year.</p>
            <p>The new pay rise carries significant implications on the role of agency nurses in public health care(Medical Staffing Ltd, 2023). Characteristically, agency nurses still have an advantage due to their ability to command favourable work terms and remuneration; however, NHS nurses still enjoy benefits afforded to them regarding work or study leave, pensions and healthcare insurance coverage(Medical Staffing Ltd, 2023). The UK governmental policy on nurse pay seem to even the playing field and encourage the retention of the nursing workforce in the public health system and limit their reliance on agency staff.</p>
            

        </div>


          <Link to="/candidate" className='lnk'>Contact us </Link>

      </div>

    </section>

    <section className="blog__vision">
      <div className="container blog-container">
        <div className="blog__section-content">
          <h3>Reference list</h3>
            <p>Department of Health and Social Care (2024). Government seeks views on new pay scale for NHS nurses. [online] GOV.UK. Available at: <a href="https://www.gov.uk/government/news/government-seeks-views-on-new-pay-scale-for-nhs-nurses">https://www.gov.uk/government/news/government-seeks-views-on-new-pay-scale-for-nhs-nurses</a> [Accessed 10 Mar. 2024].</p>
            <p>Medical Staffing Ltd (2023). Do Agency Nurses get paid more? [online] www.medicalstaffing.co.uk. Available at: <a href="https://www.medicalstaffing.co.uk/blog/2023/02/why-do-agency-nurses-get-paid-more?source=google.com">https://www.medicalstaffing.co.uk/blog/2023/02/why-do-agency-nurses-get-paid-more?source=google.com</a> [Accessed 10 Mar. 2024].</p>
            <p>ProMedical Personnel Ltd (2023). What’s Different About Agency Nurse Pay? | ProMedical. [online] ProMedical. Available at: <a href="https://www.promedical.co.uk/nursing-blogs/agency-nurse-pay/">https://www.promedical.co.uk/nursing-blogs/agency-nurse-pay/</a> [Accessed 10 Mar. 2024].</p>
            <p>Royal College of Nursing (2024). Pay justice now: government must enhance nursing salaries to combat NHS staffing crisis | News | Royal College of Nursing. [online] The Royal College of Nursing. Available at: <a href="https://www.rcn.org.uk/news-and-events/news/uk-pay-justice-now-government-must-enhance-nursing-salaries-to-combat-nhs-staffing-crisis-230224">https://www.rcn.org.uk/news-and-events/news/uk-pay-justice-now-government-must-enhance-nursing-salaries-to-combat-nhs-staffing-crisis-230224</a> [Accessed 10 Mar. 2024].</p>
            
        </div>
      </div>
    </section>

    </>
  )
}

export default Blog2